<template>
	<div>
		<transition name="fade">
			<div class="preloader" v-if="!loaded">
				<div class="spinner"></div>
			</div>
		</transition>
		<div class="car-details container py-5" v-if="isReady">
			<v-container style="max-width: 85rem" class="pt-0 px-0 d-flex flex-row">
				<filter-bread-crumb :car-details="carDetails" /> <v-spacer></v-spacer>

				<social-share :details="carDetails" />
			</v-container>
			<div class="d-flex flex-row text-h5 font-weight-bold pb-5">
				{{ carDetails.title }}
			</div>
			<v-row>
				<v-col cols="12" md="8" style="position: relative">
					<detail-photos :photos="photos" :car-id="carDetails.id" />
					<div class="advantage-car" v-if="carDetails.hasAdvantaged">
						<v-chip class="ma-2" color="template-color" text-color="template-text">
							Fırsat aracı
							<v-icon right small> fas fa-star</v-icon>
						</v-chip>
					</div>
				</v-col>
				<v-col cols="12" md="4" style="position: relative">
					<information :car-details="carDetails" />
					<contact-form @send-form="sendContactForm" />
					<div class="mt-2" :class="{ 'mt-0 to--option': !$vuetify.breakpoint.mobile }" v-if="hasCarReservation">
						<v-btn
							depressed
							class="font-weight-bold template-text--text"
							:dark="!isReserved"
							:light="isReserved"
							:block="$vuetify.breakpoint.mobile"
							:color="isReserved ? 'orange darken-3' : 'template-color darken-3'"
							@click="reserve"
							:disabled="isReserved"
						>
							<v-icon left>{{ isReserved ? 'fa-check' : 'fa-tag' }}</v-icon>
							{{ isReserved ? 'Opsiyonlu' : 'Opsiyonla' }}
						</v-btn>
					</div>
				</v-col>
			</v-row>
			<credit-calculator :data-value="creditCalculatorOptions" v-if="isCalculateCredit" />
			<div class="my-5 clear"></div>
			<v-card flat tile light color="grey lighten-4 card-shadow">
				<v-tabs
					v-model="base.detailTabs"
					dark
					slider-color="template-color darken-2"
					:fixed-tabs="false"
					show-arrows
					class="details-tab"
					v-if="!$vuetify.breakpoint.mobile"
				>
					<v-tab>
						{{ $capitalize('Açıklama ve Araç Bilgileri') }}
					</v-tab>
					<v-tab>
						{{ $capitalize('Hasar Bilgileri') }}
					</v-tab>
				</v-tabs>

				<v-tabs-items v-model="base.detailTabs" class="grey lighten-4" light v-if="!$vuetify.breakpoint.mobile">
					<v-tab-item transition="fade-transition" reverse-transition="fade-transition">
						<description :data="carDetails.description" v-if="carDetails.description" />
						<technical-spec :data="techSpecsData" />
					</v-tab-item>
					<v-tab-item transition="fade-transition" reverse-transition="fade-transition">
						<tramer :data="tramer" />
					</v-tab-item>
				</v-tabs-items>
				<div class="py-5" v-else>
					<h3 class="font-weight-bold">
						<v-icon left>fas fa-caret-right</v-icon>
						{{ $capitalize('Açıklama ve Araç Bilgileri') }}
					</h3>
					<description :data="carDetails.description" />
					<technical-spec :data="techSpecsData" />
					<h3 class="font-weight-bold">
						<v-icon left>fas fa-caret-right</v-icon>
						{{ $capitalize('Hasar Bilgileri') }}
					</h3>
					<tramer :data="tramer" />
				</div>
			</v-card>
		</div>
		<div class="car-details container py-16" v-else>
			<div style="font-size: 5em" class="my-0 font-weigh-600">404</div>
			<div class="mt-n7 mb-5" style="font-size: 1.4em">Aradığınız kriterlere uygun bir araç bulunamadı.</div>
			<div>
				<v-btn @click="$router.push({ name: 'cars' })" outlined color="button-color">
					<v-icon color="button-color" small left>fa-chevron-left</v-icon>
					Listeye dön
				</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
import { GET_CAR_BY_ID } from '@/store/modules/listing.module';
import { SEND_CONTACT_FORM } from '@/store/modules/listingContact.module';
import { mapActions, mapGetters } from 'vuex';
import ContactForm from './components/contactForm.vue';
import CreditCalculator from './components/creditCalculator.vue';
import Description from './components/description.vue';
import DetailPhotos from './components/detailPhotos.vue';
import FilterBreadCrumb from './components/filterBreadCrumb.vue';
import Information from './components/information.vue';
import SocialShare from './components/socialShare.vue';
import TechnicalSpec from './components/technicalSpec.vue';
import Tramer from './components/tramer.vue';

export default {
	name: 'CarDetails',
	components: {
		Information,
		DetailPhotos,
		TechnicalSpec,
		Description,
		Tramer,
		FilterBreadCrumb,
		SocialShare,
		ContactForm,
		CreditCalculator,
	},
	data() {
		return {
			noSuchCar: false,
			base: {
				detailTabs: null,
			},
			data: {
				carDetails: null,
			},
			loaded: false,
		};
	},
	mounted() {
		this.$nextTick(() => {
			this.getCarDetails(this.carId)
				.then((response) => {
					this.data.carDetails = response;

					this.noSuchCar = false;
				})
				.catch((err) => {
					if (err.responseCode === 4000) {
						this.noSuchCar = true;
					}
				})
				.finally(() => {
					this.loaded = true;
				});
		});
	},
	computed: {
		...mapGetters(['hasCarReservation', 'listingCars', 'isFetching']),
		creditCalculatorOptions() {
			if (this.carDetails.creditInformation.installments === null) return null;

			return {
				mainSalesPrice: this.carDetails?.salesPrice,
				price: this.carDetails.creditInformation.price,
				maxCreditRate: this.carDetails.creditInformation.creditPriceRate,
				installments: this.carDetails.creditInformation.installments,
				interestRate: this.carDetails.creditInformation.creditRate,
				creditKkdf: this.carDetails.creditInformation.creditKkdf,
				creditBsmv: this.carDetails.creditInformation.creditBsmv,
			};
		},
		isCalculateCredit() {
			return this.carDetails.creditInformation.isCalculateCredit && this.creditCalculatorOptions !== null;
		},
		isReady() {
			return !this.isFetching && this.carDetails && !this.noSuchCar;
		},
		carId() {
			return this.$route.params.id;
		},
		carDetails() {
			return this.data.carDetails;
		},
		isReserved() {
			return this.carDetails?.isReserved;
		},
		photos() {
			return this.carDetails.photos;
		},
		techSpecsData() {
			return {
				techSpecCategories: this.carDetails.techSpecCategories,
				equipmentCategories: this.carDetails.equipmentCategories,
			};
		},
		tramer() {
			return {
				cidLeftFrontFender: this.carDetails.leftFrontFender,
				cidRightFrontFender: this.carDetails.rightFrontFender,
				cidLeftFrontDoor: this.carDetails.leftFrontDoor,
				cidRightFrontDoor: this.carDetails.rightFrontDoor,
				cidLeftRearDoor: this.carDetails.leftRearDoor,
				cidRightRearDoor: this.carDetails.rightRearDoor,
				cidLeftRearFender: this.carDetails.leftRearFender,
				cidRightRearFender: this.carDetails.rightRearFender,
				cidFrontHood: this.carDetails.frontHood,
				cidRearHood: this.carDetails.rearHood,
				cidCeiling: this.carDetails.ceiling,
				cidFrontBumper: this.carDetails.frontBumper,
				cidRearBumper: this.carDetails.rearBumper,
			};
		},
		hasCarReservation() {
			return this.$store.getters.hasCarReservation || this.isReserved;
		},
	},
	methods: {
		...mapActions({
			getCarDetails: GET_CAR_BY_ID,
			createContactForm: SEND_CONTACT_FORM,
		}),
		reserve() {
			if (this.isReserved) return;
			this.$router.push({ name: 'optionStart', params: { 'car-value': this.carDetails, id: this.carId } });
		},
		sendContactForm(contactForm, callback) {
			const payload = { ...contactForm, listingKey: this.carId };

			this.createContactForm(payload)
				.then(() => {
					this.$router.replace({ query: { mesaj: 'bilgi-al', durum: 'basarili' } });
					callback(true);
				})
				.catch(() => {
					this.$router.replace({ query: { mesaj: 'bilgi-al', durum: 'mesaj-gonderilemedi' } });
					callback(false);
				});
		},
	},
};
</script>
<style lang="scss" scoped>
.preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #fff;
	z-index: 9999;
	opacity: 1;
	transition: opacity 1s;

	&.fade-out {
		opacity: 0;
	}

	.spinner {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 60px;
		height: 60px;
		margin: -30px 0 0 -30px;
		border: 5px solid var(--v-template-color-base);
		border-top-color: var(--v-template-color-darken3);
		border-radius: 100%;
		animation: spin 1s ease-in-out infinite;
	}

	@keyframes spin {
		to {
			transform: rotate(360deg);
		}
	}
}
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-active {
	opacity: 0;
}

.car-details {
	&.container {
		width: 100%;
		max-width: 85rem !important;
	}

	.v-card__text,
	.v-card__subtitle {
		color: rgba(0, 0, 0, 1);
	}

	.card-shadow {
		box-shadow: 0px 0px 20px 0px rgb(233, 233, 233) !important;
	}

	.to--option {
		transform: rotate(90deg);
		right: -76px;
		top: 200px;
		position: absolute;
	}
}
</style>
